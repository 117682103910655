import styled from 'styled-components'

export const Container = styled.div`
  max-width: 500px;
  margin: 30px auto 0;
`

export const Title = styled.span`
  font-size: ${({ theme: { size } }) => size.fontSize.l};
  color: ${({ theme: { color } }) => color.secondary.main};
`

export const P = styled.p`
  margin-bottom: 0px;
  line-height: 1.3;
  text-align: justify;
`

export const Section = styled.div`
  margin-bottom: 20px;
`
